.aboutus {
  width: 100vw;
  height: 100%; /* Set the height to occupy the full viewport height */
  /* display: flex; */
  /* Add flex display to center the image */
  /* align-items: center;  */
  /* Center vertically */
  /* justify-content: center;  */
  /* Center horizontally */
  position: relative;
}

.aboutus img {
  max-width: 100%; /* Ensure the image doesn't exceed the container's width */
  max-height: 100%; /* Ensure the image doesn't exceed the container's height */
  width: auto;
  height: auto;
}
.about-btn {
  position: absolute;
  top: 62%;
  left: 69%;
  width: 15rem;
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 100px;
  color: #fff;
  letter-spacing: 2px;
  cursor: pointer;
  transition: 0.3s linear;
  background-color: #2a5e38;
  border: 2px solid #fbe284;
  cursor: pointer;
}
.about-btn :hover {
  transform: scale(1.1);
}
.aboutimgmobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .aboutusimg {
    display: none;
  }
  .aboutimgmobile {
    display: block;
  }
  .about-btn {
    left: 50%;
    transform: translateX(-50%);
    top: 79%;
  }
}
