make variables :root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: #f48915;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ffa739 100.75%);
  --appColor: #3c3f45;
}
html {
  scroll-behavior: smooth;
}

.App {
  /* mix-blend-mode: overlay; */
  background: #3c3f45;

  background: rgb(184, 108, 38);
  background: radial-gradient(
    circle,
    rgba(184, 108, 38, 1) 0%,
    rgba(46, 44, 44, 1) 100%
  );
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  gap: 6rem;
  overflow: hidden;
}
.loaders {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: #3c3f45;
}
.stroke-text {
  color: transparent;
  /* font-family: Arial, Helvetica, sans-serif; */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.btn {
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.btn:hover {
  cursor: pointer;
}
.blur {
  background: rgba(253, 120, 43, 0.69);
  position: absolute;

  border-radius: 50%;

  filter: blur(225px);
  z-index: 0;
}
a {
  color: white;
  text-decoration: none;
}
/* a:hover {
  color: #f48915;
} */
.header {
  padding-left: 1rem;
}
.line {
  width: 100vw;
  background-color: #f48915;
  height: 0.5rem;
}
