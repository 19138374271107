/* HEADER CSS */

.header {
  display: flex;
  justify-content: space-between;
  color: white;
}

.dnd {
  height: 5rem;
}
.logo {
  width: 10rem;
  height: auto;
  margin: -0.25rem -1rem;
}
.header-menu {
  display: flex;
  list-style: none;
  gap: 2rem;
}
.header-menu > li {
  cursor: pointer;
  transition: 0.2s ease all;
  align-self: center;
}
.header-menu > li:hover {
  /* color: orange; */
  text-decoration: underline;
  /* font-size: 1.1rem; */
}
.dropdown-menu {
  display: none;
  backdrop-filter: blur(0.5rem);
  /* position: fixed; */
  position: absolute;
  top: 4rem;
  right: 15rem;
  transform: 1s linear all;
  /* padding-left: -1rem; */
  padding: 1.2rem 1.5rem;
  background-color: hsla(220, 7%, 25%, 0.9);
}
.dropdown-menu-2 {
  display: none;
  backdrop-filter: blur(0.5rem);
  /* position: fixed; */
  position: absolute;
  top: 4rem;
  right: 10rem;
  transform: 1s linear all;
  /* padding-left: -1rem; */
  padding: 1.2rem 1.5rem;
  background-color: hsla(220, 7%, 25%, 0.9);
}
.dropdown-menu > li {
  margin-top: 1rem;
}
.dropdown-menu-2 > li {
  margin-top: 1rem;
}
.dropdown {
  display: block;
  transform: 1s linear all;
}
.menu-item .sub__menus__arrows {
  position: fixed;
  transform: 1s linear all;
}
@media screen and (max-width: 786px) {
  .header {
    margin-top: 2rem;
  }
  .header > :nth-child(2) {
    position: fixed !important;
    right: 2rem;
    z-index: 99;
  }
  .dropdown-menu {
    /* right: 12.7rem; */
    right: 0.69999999999993rem;
    top: 15.5rem;
    width: max-content;
    background-color: #f48915;
    padding: 1rem 1.5rem;
    border-radius: 10px 30px;
  }
  .dropdown-menu-2 {
    /* right: 12.7rem; */
    right: 0.69999999999993rem;
    top: 18.5rem;
    width: max-content;
    background-color: #f48915;
    padding: 1rem 1.5rem;
    border-radius: 10px 30px;
  }
  .dropdownMenuItemClassName:hover {
    color: rgb(252, 219, 190);
    font-weight: 500;
  }
  .dropdown-menu > li > NavLink:hover {
    color: white;
  }
  .header-menu {
    flex-direction: column;
    background-color: #3c3f45;
    padding: 1rem;
    transition: 0.5s all;
  }
  .logo {
    width: 7rem;
    height: auto;
    margin: -2rem -2rem;
  }
}
ul {
  transition: 0.5s all;
}

@media screen and (min-width: 786px) {
  .header {
    position: fixed;
    z-index: 99;
    /* margin-left: -2rem; */
    width: 100vw !important;
    /* backdrop-filter: blur(10px); */
    /* margin-bottom: 2rem; */
  }
  .sticky {
    backdrop-filter: blur(25px);
    /* background-color: hsla(31, 91%, 52%, 0.5); */
    /* background-color: #f48915; */
    /* background-color: hsla(220, 7%, 25%, 0.7); */
    /* background-color: #3c3f45; */
  }
  .header-menu {
    padding-right: 2rem;
  }
}
