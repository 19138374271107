.blog {
  margin: 50px 0;
  width: 100vw;
}
.blog .boxItems {
  transition: 0.5s;
}
.box .boxItems .boxItem {
  background-color: #9c9c9c !important;
}
.blog .boxItems:hover {
  box-shadow: 0 2px 4px #9c9c9c;
  cursor: pointer;

  transform: translateY(-5px);
}
.boxItems {
  border: 1px solid #9c9c9c;
  border-radius: 12px;
  padding: 20px;
  /* background: #fff; */
  background: gray;
}
.blog img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}
.blog .icon {
  margin-right: 10px;
  font-size: 20px;
}
.blog .tag a {
  color: rgb(255, 107, 1);
  /* opacity: 0.7; */
  font-weight: 600;
}
.blog .tag {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: white;
}
.blog h3 {
  font-weight: 500;
  color: white;
}
.blog p {
  color: #999;
  font-weight: 400;
  margin: 20px 0;
  font-size: 17px;
  line-height: 25px;
}
.blog .date {
  display: flex;
  align-items: center;
}
.blog .date label {
  display: block;
  margin-right: 20px;
}
.details {
  color: white;
}
.details {
  color: white;
}
.blog-card-para {
  color: white !important;
  font-size: 1rem;
  font-style: italic;
}

.grids {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}
@media screen and (max-width: 768px) {
  .grids {
    grid-template-columns: repeat(1, 1fr);
  }
}
