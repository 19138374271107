.common-heading {
  font-size: 3.8rem;
  font-weight: 600;
  margin-bottom: 6rem;
  color: #f48915;
  margin-top: -1rem;
}

input,
textarea {
  max-width: 50rem;
  color: #000;
  padding: 1.6rem 2.4rem;
  border: 1px solid rgba(98, 84, 243, 0.5);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
/* input[type="submit"] {
  max-width: 16rem;
  margin-top: 2rem;
  background-color: #00df9a;
  color: #fff;
  padding: 1.4rem 2.2rem;
  border-style: solid;
  border-width: 0.1rem;

  font-size: 1.8rem;
  cursor: pointer;
} */
.contact-subheading {
  color: white;
  font-size: 2rem;
  margin-top: -2.5rem;
  margin-bottom: 2rem;
}
.btn-join {
  background-color: #f48915;
  color: white;
}
