.serve {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  gap: 1rem;
}

.serve > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 12rem;
  gap: 0.2rem; /* Adjust the gap as needed for consistent spacing */
  color: #fff;
}
.serve > div > h4 {
  margin: 1rem -1rem; /* Adjust the margin as needed for the desired spacing */
}

.serve > div > p {
  font-size: 0.7rem;
  margin-top: 1rem;
}

.serve > div > img {
  height: 10rem;
}

.programs-header {
  display: flex;
  gap: 2rem;
}

/* Add the following media query */
@media screen and (max-width: 768px) {
  .programs-header {
    flex-direction: column;
    align-items: center;
  }

  .programs-header span {
    display: inline-block;
    margin: 2rem; /* Adjust the margin as needed for spacing */
  }
}
