.blog-heading {
  text-align: center;
  margin-top: 7rem;
  font-size: 2.5rem;
  color: #f48915;
  letter-spacing: 2px;
}

.category {
  /* margin-top: 2rem; */
  position: relative;
  width: 90vw;
  margin: 0 auto;
}
.category .box {
  border-radius: 0;
  text-align: center;
}
.category p {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
}
.category img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.category .box::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: rgba(0, 0, 0, 0.5);
}
.category .box {
  cursor: pointer;
  position: relative;
}
.category .overlay {
  position: absolute;
  top: 80px;
  /* padding: 20px; */
  text-align: center;
  width: 100%;
  z-index: 2;
  color: #fff;
}
.category .overlay h4 {
  font-weight: 500;
  color: rgb(253, 246, 126);
}
.category .overlay p {
  color: #fff;
  font-weight: 300;
  /* padding: 15px; */
}
/*---------------control-btn-------------*/
.control-btn button {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  padding: 2px;
  /* color: #f48915; */
}

.control-btn .next {
  position: absolute;
  top: 40%;
  right: 20px;
  /* fill: #f48915; */
  /* color: #f48915; */
  /* background-color: #f48915; */
}

.control-btn .prev {
  position: absolute;
  top: 40%;
  left: 20px;
  /* color: #f48915 !important; */
  z-index: 99;
}
.control-btn .icon {
  font-size: 25px;
  background: #f48915;
}
/*---------------control-btn-------------*/
