/* Join.css */
.join {
  display: flex;
  flex-direction: column; /* Display as a column */
  padding: 0 2rem;
  gap: 2rem;
  width: 100%;
}

.left-j {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  align-self: flex-start;
}

.left-j > hr {
  position: absolute;
  width: 10.5rem;
  border: 2px solid #f48915;
  border-radius: 20%;
  margin: -10px 0;
}

.left-j > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.right-j {
  display: flex;
  justify-content: center;
  align-items: center; /* Center the form horizontally */
  flex: 1 1;
}

.email-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: gray;
  padding: 1rem;
  max-width: 300px;
}

.email-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: #d9d9d9;
  padding: 0.5rem;
}

.btn-join {
  background-color: #f48915;
  color: white;
  padding: 0.5rem 1rem;
}

@media screen and (max-width: 786px) {
  .join {
    flex-direction: column;
    gap: 1rem;
  }

  .left-j {
    font-size: x-large;
    align-items: center;
  }

  .right-j {
    align-items: center;
  }

  .email-container {
    max-width: 100%;
  }
}
